import React, { useEffect } from 'react';

// mui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutView: React.FC = () => {
    const { logout } = useAuth0();

    useEffect(() => {
        Cookies.remove('auth0_user', { path: '/', domain: '.organdata.org' });
        Cookies.remove('auth0_token', { path: '/', domain: '.organdata.org' });
        logout();
        window.location.pathname = '/';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Add dependencies

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ bgcolor: '#0b0b0b', height: '100vh' }}>
            <Grid item xs={12} md={4}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Typography variant="body1">Loading...</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LogoutView;
