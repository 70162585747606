import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import * as notificationActions from './NotificationActions';

const AlertMessage = React.forwardRef<HTMLDivElement, AlertProps>(function AlertMessage(props, ref) {
    return <Alert elevation={6} ref={ref} {...props} />;
});

const Notification: React.FC = () => {
    const dispatch = useDispatch();
    const notification = useSelector((state: any) => state.notifications);

    if (notification) {
        const { open = false, severity = 'success', message = 'Success!' } = notification;
        const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }

            // @ts-ignore
            dispatch(notificationActions.hideNotification());
        };

        return (
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <AlertMessage onClose={handleClose} severity={severity}>
                    {message}
                </AlertMessage>
            </Snackbar>
        );
    }
    return null;
};

// middleware
export default Notification;
