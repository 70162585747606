import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import { FormControl, FormHelperText, Grid, Tooltip, Typography } from '@mui/material';

export const FreeTextSearch = (props: any) => {
    const { value, onHandleChange, onHandleQuickSearch } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <FormControl variant="outlined" fullWidth>
            <TextField
                id="filled-search"
                label="Quick Text Search"
                type="search"
                variant="outlined"
                value={value}
                onChange={onHandleChange}
                size="small"
                onKeyUpCapture={(ev) => {
                    if (ev.key === 'Enter') {
                        onHandleQuickSearch();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={onHandleQuickSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Tooltip
                title={
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={'caption'}>
                                Enter keywords in the search box. The results will include records containing all the
                                entered keywords.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'caption'}>Use quotes for exact phrases:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'caption'}>
                                "targeted therapy": Finds records with the exact phrase "targeted therapy".
                            </Typography>
                        </Grid>
                    </Grid>
                }
            >
                <FormHelperText>
                    <Typography
                        variant="inherit"
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        <Grid container alignItems="center">
                            <Grid item>
                                <InfoIcon sx={{ fontSize: 20, paddingRight: '4px' }} color="disabled" />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" sx={{ paddingTop: '2px' }}>
                                    Filter analytics by entering keywords
                                </Typography>
                            </Grid>
                        </Grid>
                    </Typography>
                </FormHelperText>
            </Tooltip>
        </FormControl>
    );
};

export default FreeTextSearch;
