const fields = [
    {
        id: 'State Name',
        type: 'list',
        label: 'State Name',
    },
    {
        id: 'Award Year',
        type: 'list',
        label: 'Award Year',
    },
    {
        id: 'Grantee Type Description',
        type: 'list',
        label: 'Grantee Type Description',
    },
    {
        id: 'HRSA Program Area Name',
        type: 'list',
        label: 'HRSA Program Area Name',
    },
    {
        id: 'Grant Program Name',
        type: 'list',
        label: 'Grant Program Name',
    },
    {
        id: 'Financial Assistance',
        type: 'typeahead',
        label: 'Financial Assistance',
    },
];

export default fields;
