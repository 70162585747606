import React, { useState } from 'react';
import ReactPlayer from 'react-player';

// components and helpers
import acoerLogo from '../assets/images/acoerLogo.png';
import ComponentWrapper from '../containers/ComponentWrapper';

// mui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { List, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextField from '@mui/material/TextField';

const CompanionView: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [contactInfo, setContactForm] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const disabled = !contactInfo.name || !contactInfo.email || !contactInfo.phone || !contactInfo.message;

    const bulletPoints = [
        'AI-Driven Search: The AI prompt bot leverages advanced algorithms to efficiently discover and identify relevant cancer clinical trials from organ-procurement.gov reflected in our Explorer.',
        'Natural Language Processing: Utilizing natural language processing, the bot understands and responds to user queries in a conversational manner, making it easy to find specific clinical trials.',
        'User-Friendly Interface: The bot features an intuitive interface, allowing users to interact seamlessly and obtain information quickly.',
        'Transparency and Accountability: This tool stands out for its commitment to ethical AI, using Hedera data stamping and lineage tracking to securely record every AI-driven decision, ensuring a fully transparent and trustworthy process.',
    ];

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactForm({
            ...contactInfo,
            [event.target.name]: event.target.value,
        });
    };
    return (
        <div
            style={{
                backgroundRepeat: 'no-repeat',
                minHeight: '100%',
                marginTop: 50,
            }}
        >
            <Grid container justifyContent={'center'} alignItems={'center'} spacing={6}>
                <Grid item xs={11} style={{ marginTop: 30 }}>
                    <Paper elevation={0} style={{ padding: 40, borderRadius: 15, paddingBottom: matches ? 300 : 0 }}>
                        <Grid container spacing={4} alignItems={'center'}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant={'h4'} style={{ fontWeight: 'bold' }} color={'primary'}>
                                            Cancer Trials AI Companion
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Acoer's AI Companion for Cancer Trials leverages advanced algorithms to
                                            efficiently discover relevant cancer clinical trials from
                                            ClinicalTrials.gov, utilizing natural language processing to understand user
                                            queries in a conversational manner and featuring a user-friendly interface
                                            for seamless interaction and quick access to information.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List>
                                            {bulletPoints.map((value: string, index: number) => {
                                                return (
                                                    <ListItem key={`homepage_bullet_${index}`}>
                                                        <ListItemAvatar>
                                                            <CheckCircleIcon color={'primary'} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    variant={'subtitle2'}
                                                                    style={{ fontWeight: 'bold' }}
                                                                >
                                                                    {value.split(':').shift()}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <Typography variant={'subtitle2'}>
                                                                    {value.split(':').pop()}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                                <ReactPlayer
                                    url={'https://www.youtube.com/watch?v=A-pQ89jwdNg'}
                                    controls={true}
                                    style={{
                                        borderRadius: '50%',
                                        display: 'block',
                                        margin: 'auto',
                                        maxWidth: '100%',
                                        position: matches ? 'absolute' : 'relative',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={11} style={{ marginTop: 30 }}>
                    <Paper
                        elevation={0}
                        style={{
                            padding: 40,
                        }}
                    >
                        <form action="https://formspree.io/f/mgeggjjl" method="POST">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant={'h6'}
                                        color={'primary'}
                                        style={{
                                            fontWeight: 'bold',
                                            fontFamily: 'Inter, sans-serif',
                                        }}
                                    >
                                        Contact Acoer for more information on accessing our AI Companion for Cancer
                                        Trials.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Name"
                                        name={'name'}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        placeholder="Your Name"
                                        value={contactInfo.name}
                                        onChange={handleChange}
                                        size={'small'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Email"
                                        name={'email'}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        placeholder="you@org.com"
                                        value={contactInfo.email}
                                        onChange={handleChange}
                                        size={'small'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Phone Number"
                                        name={'phone'}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        placeholder="1 (555) 000-0000"
                                        value={contactInfo.phone}
                                        onChange={handleChange}
                                        size={'small'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Message"
                                        name={'message'}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        rows={4}
                                        multiline
                                        placeholder="Enter your message here..."
                                        onChange={handleChange}
                                        value={contactInfo.message}
                                        size={'small'}
                                    />
                                </Grid>
                                <Grid container justifyContent="flex-end">
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        size={'small'}
                                        style={{ borderRadius: 50, margin: 15 }}
                                        disableElevation
                                        disabled={disabled}
                                        type={'submit'}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={'center'} alignItems={'center'} spacing={3} direction={'column'}>
                        <Grid item>
                            <a
                                href="https://www.acoer.com/"
                                target={'_blank'}
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <img src={acoerLogo} alt="Acoer" height={50} />
                            </a>
                        </Grid>
                        <Grid item>
                            <Typography variant={'body1'}>Copyright © 2024 | All Rights Reserved</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ComponentWrapper(CompanionView);
