import {
    queries as overviewQueries,
    dependentQueries as trialDependentQueries,
    demographicQueries,
    waitlistQueries,
    opoOtcQueries,
} from './dashboards/organ-procurement/queries';
import {
    dashboardBuild as organProcurementDashboardBuild,
    demographicsBuild,
    opoOtcBuild,
    waitlistBuild,
} from './dashboards/organ-procurement/build';
import { queries as kidneyQueries, dependentQueries as kidneyDependentQueries } from './dashboards/kidney/queries';
import { dashboardBuild as kidneyDashboardBuild } from './dashboards/kidney/build';
import { queries as liverQueries, dependentQueries as liverDependentQueries } from './dashboards/liver/queries';
import { dashboardBuild as liverDashboardBuild } from './dashboards/liver/build';
import {
    queries as grantsQueries,
    dependentQueries as grantsDependentQueries,
    regionsQueries,
    tableQueries,
} from './dashboards/grants/queries';
import { dashboardBuild as grantsDashboardBuild, regionsBuild, tableBuild } from './dashboards/grants/build';

export const dashboardBuild = (type: string, setFilters: any, dashboard: any) => {
    if (type === 'overview') {
        return {
            tabs: [
                {
                    label: 'National Overview',
                    description: '',
                    builder: organProcurementDashboardBuild(setFilters, dashboard),
                    queries: overviewQueries,
                    dependentQueries: trialDependentQueries,
                },
                {
                    label: 'Waitlist',
                    description: '',
                    builder: waitlistBuild(setFilters, dashboard),
                    queries: waitlistQueries,
                },
                {
                    label: 'OPO/OTC Overview',
                    description: '',
                    builder: opoOtcBuild(setFilters, dashboard),
                    queries: opoOtcQueries,
                },
                {
                    label: 'Demographics',
                    description: '',
                    builder: demographicsBuild(setFilters, dashboard),
                    queries: demographicQueries,
                    dependentQueries: trialDependentQueries,
                },
            ],
        };
    } else if (type === 'kidney') {
        return {
            tabs: [
                {
                    label: 'KIDNEYS YAY',
                    description: '',
                    builder: kidneyDashboardBuild(setFilters, dashboard),
                    queries: kidneyQueries,
                    dependentQueries: kidneyDependentQueries,
                },
                {
                    label: 'KIDNEYS2 YAY',
                    description: '',
                    builder: kidneyDashboardBuild(setFilters, dashboard),
                    queries: kidneyQueries,
                    dependentQueries: kidneyDependentQueries,
                },
            ],
        };
    } else if (type === 'liver') {
        return {
            tabs: [
                {
                    label: 'LIVERS YAY',
                    description: '',
                    builder: liverDashboardBuild(setFilters, dashboard),
                    queries: liverQueries,
                    dependentQueries: liverDependentQueries,
                },
                {
                    label: 'LIVERS2 YAY',
                    description: '',
                    builder: liverDashboardBuild(setFilters, dashboard),
                    queries: liverQueries,
                    dependentQueries: liverDependentQueries,
                },
            ],
        };
    } else if (type === 'grants') {
        return {
            tabs: [
                {
                    label: 'Overview',
                    description: '',
                    builder: grantsDashboardBuild(setFilters, dashboard),
                    queries: grantsQueries,
                    dependentQueries: grantsDependentQueries,
                },
                {
                    label: 'Grants by Region',
                    description: '',
                    builder: regionsBuild(setFilters, dashboard),
                    queries: regionsQueries,
                    dependentQueries: grantsDependentQueries,
                },
                {
                    label: 'Grant List',
                    description: '',
                    builder: tableBuild(setFilters, dashboard),
                    queries: tableQueries,
                    dependentQueries: grantsDependentQueries,
                },
            ],
        };
    }
};
