import datatableTheme from '../assets/jss/Components/DatatableStyles';

//adds mui theme
export const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                  // palette values for light mode
                  primary: {
                      main: '#E8C658',
                  },
                  secondary: {
                      main: '#008577',
                  },
                  background: {
                      default: '#F0F2F5',
                      paper: '#F9FBFF !important',
                  },
                  text: {
                      primary: '#333333',
                      secondary: '#999ba8',
                  },
              }
            : {
                  // palette values for dark mode
                  primary: {
                      main: '#fff',
                  },
                  secondary: {
                      main: '#398377',
                  },
                  background: {
                      default: '#171C19',
                      paper: '#1E2423',
                  },
                  text: {
                      primary: '#FFFFFF',
                      secondary: '#999ba8',
                  },
              }),
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: { borderRadius: 4 },
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    minWidth: '100px !important',
                    backgroundColor: '#f0f4f9 !important',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: `#fff !important`,
                    '&.Mui-selected': {
                        backgroundColor: `#FFDB58 !important`,
                        color: `#0b0b0b !important`,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                outlinedPrimary: {
                    borderColor: mode === 'light' ? '#0b0b0b' : '#fff',
                    color: mode === 'light' ? '#0b0b0b' : '#fff',
                    textTransform: 'none',
                },
                containedPrimary: {
                    backgroundColor: mode === 'light' ? '#FFDB58' : '#398377',
                    color: '#000',
                    textTransform: 'none',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    overflowY: 'clip !important',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
                            @font-face {
                              font-family: 'Inter';
                              font-style: normal;
                              font-display: swap;
                              src: local('Inter'), local('Inter-Regular'), url(./assets/fonts/Inter/Inter-Regular.ttf) format('ttf');
                              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                            }
                            #workspace-analytics #analytics-tab-wrapper {
                                position: sticky;
                                top: 60px;
                                margin-left: 0px;
                                padding-right: 10px;
                                width: 100%;
                                z-index: 1200;
                                padding-bottom: 3px;
                                background-color: ${mode === 'dark' ? '#171C19' : '#F0F2F5'};
                            }
                            #workspace-analytics #analytics-dashboard {
                                background-color: ${mode === 'dark' ? '#1E2423' : '#F9FBFF !important'};
                                width: 100%;
                                border-radius: 5px;
                                margin: auto;
                                padding-bottom: 40px;
                                padding-right: 48px;
                            }
                            #workspace-analytics .analytics-chart:has(&.MuiTable-root) {
                                background-color: transparent !important;
                                border-radius: 5px;
                                margin: 0px !important;
                                padding: 0px !important;
                                margin-top: -60px !important;
                            }
                          `,
        },
        ...datatableTheme(mode),
    },
});
