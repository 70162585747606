const fields = [
    {
        id: 'organ',
        type: 'list',
        label: 'Organ',
    },
    {
        id: 'state',
        type: 'typeahead',
        label: 'State',
    },
];

export default fields;
