import React from 'react';

// components and helpers
import ComponentWrapper from '../containers/ComponentWrapper';
import notFoundImage from '../assets/images/Acoer-404-asset-v3.svg';

// mui
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

const NotFoundView: React.FC = () => {
    const theme = useTheme();
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} md={4} style={{ paddingTop: 50, paddingBottom: 80 }}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12}>
                        <img src={notFoundImage} alt="" />
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'} style={{ color: theme.palette.primary.light }}>
                            Sorry! This page could not be found.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ComponentWrapper(NotFoundView);
