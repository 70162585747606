import candidatesIcon from '../../../../../../assets/images/CandidatesIcon.svg';
import donorsIcon from '../../../../../../assets/images/DonorsIcon.svg';
import recipientIcon from '../../../../../../assets/images/TransplantRecipientIcon.svg';

const aggregateStateCounts = (data: any) => {
    const groupedData = {};

    data.items.forEach((item: { _id: { state: any; organ: any; counts: any } }) => {
        const { state, organ, counts } = item._id;
        const parsedCounts = parseInt(counts, 10);

        // Skip if counts are NaN or not a number
        if (isNaN(parsedCounts)) {
            return;
        }

        if (!groupedData[state]) {
            groupedData[state] = { groupedBy: state };
        }

        if (!groupedData[state][organ]) {
            groupedData[state][organ] = 0;
        }

        groupedData[state][organ] += parsedCounts;
    });

    // Convert the groupedData object to an array of objects with your desired format
    const result = Object.values(groupedData);
    // @ts-ignore
    return result.sort((a, b) => a.groupedBy.localeCompare(b.groupedBy));
};
const aggregateStateTotal = (data: any) => {
    if (data && data.items && data.items.length) {
        const totalCounts = {};

        data.items.forEach((item: { COUNT?: number; state?: string; _id: { state: any; counts: any } }) => {
            const { state, counts } = item._id;
            const value = item.COUNT || counts;
            const stateValue = item.state || state;
            const parsedCounts = parseInt(value, 10);

            // Skip if counts are NaN or not a number
            if (isNaN(parsedCounts)) {
                return;
            }

            if (!totalCounts[stateValue]) {
                totalCounts[stateValue] = 0;
            }

            // Add the count to the total for the state
            totalCounts[stateValue] += parsedCounts;
        });

        // Convert the totalCounts object into an array of objects with 'id' and 'value' keys
        return Object.keys(totalCounts).map((state) => ({
            id: state,
            value: totalCounts[state],
        }));
    }
};

export const dashboardBuild = (setFilters: any, dashboard: any) => {
    return [
        {
            type: 'iconCount',
            width: 4,
            count: {
                title: 'Transplant Recipients (YTD)',
                // color: 'linear-gradient(to right bottom, #FFDB58 40%, #FFDB5899) !important',
                // color: '#0b0b0b !important',
                color: '#fff !important',
                primaryTextColor: '#0b0b0b !important',
                secondaryTextColor: '#E8C658 !important',
                paperElevation: 1,
                image: recipientIcon,
            },
            dataTransformer: (data: any, chart: any) => {
                if (data && data.items && data.items.length === 0) return 0;
                if (data && data.items && data.items.length) {
                    const deceasedDonor = data.items[0]['deceased_donor_organ_transplant_recipients'];
                    const livingDonor = data.items[0]['living_donor_organ_transplant_recipients'];
                    return (deceasedDonor + livingDonor).toLocaleString();
                }
            },
            dataType: 'national_overview',
            includeChartTitle: false,
            height: 150,
        },
        {
            type: 'iconCount',
            width: 4,
            count: {
                title: 'Donors Recovered (YTD)',
                color: '#fff !important',
                primaryTextColor: '#0b0b0b !important',
                secondaryTextColor: '#E8C658 !important',
                paperElevation: 1,
                image: donorsIcon,
            },
            dataTransformer: (data: any, chart: any) => {
                if (data && data.items && data.items.length === 0) return 0;
                if (data && data.items && data.items.length) {
                    const deceasedDonor = data.items[0]['deceased_organ_donors_recovered'];
                    const livingDonor = data.items[0]['living_organ_donors_recovered'];
                    return (deceasedDonor + livingDonor).toLocaleString();
                }
            },
            dataType: 'national_overview',
            includeChartTitle: false,
            height: 150,
        },
        {
            type: 'iconCount',
            width: 4,
            count: {
                title: `Candidates Added (YTD)`,
                color: '#fff !important',
                primaryTextColor: '#0b0b0b !important',
                secondaryTextColor: '#E8C658 !important',
                paperElevation: 1,
                image: candidatesIcon,
            },
            dataTransformer: (data: any, chart: any) => {
                if (data && data.items && data.items.length === 0) return 0;
                if (data && data.items && data.items.length) {
                    return data.items[0]['candidates_added'].toLocaleString();
                }
            },
            dataType: 'national_overview',
            includeChartTitle: false,
            height: 150,
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    const arrayObj =
                        data &&
                        data.items &&
                        data.items.length &&
                        data.items.map((item: any) => {
                            return {
                                groupedBy: item['_id'].organ,
                                living_donor_organ_transplant_recipients:
                                    item['_id']['living_donor_organ_transplant_recipients'] === '.'
                                        ? 0
                                        : item['_id']['living_donor_organ_transplant_recipients'],
                                deceased_donor_organ_transplant_recipients:
                                    item['_id']['deceased_donor_organ_transplant_recipients'] === '.'
                                        ? 0
                                        : item['_id']['deceased_donor_organ_transplant_recipients'],
                                living_organ_donors_recovered:
                                    item['_id']['living_organ_donors_recovered'] === '.'
                                        ? 0
                                        : item['_id']['living_organ_donors_recovered'],
                                deceased_organ_donors_recovered:
                                    item['_id']['deceased_organ_donors_recovered'] === '.'
                                        ? 0
                                        : item['_id']['deceased_organ_donors_recovered'],
                                candidates_added:
                                    item['_id']['candidates_added'] === '.' ? 0 : item['_id']['candidates_added'],
                                registrations_added:
                                    item['_id']['registrations_added'] === '.' ? 0 : item['_id']['registrations_added'],
                            };
                        });
                    return {
                        data: arrayObj,
                        keys: [
                            'living_donor_organ_transplant_recipients',
                            'deceased_donor_organ_transplant_recipients',
                            'living_organ_donors_recovered',
                            'deceased_organ_donors_recovered',
                            'candidates_added',
                            'registrations_added',
                        ],
                    };
                }
            },
            chartTitle: 'National Overview by Organ (YTD)',
            width: '12',
            groupMode: 'stacked',
            layout: 'horizontal',
            dataType: 'national_overview_by_organ',
            xAxisRotate: false,
        },
        {
            type: 'map',
            width: 6,
            mapType: 'cloropleth',
            mapColor: 'blue',
            center: ['39.8283', '-98.5795'],
            zoom: '4',
            dataTransformer: (data: any, chart: any) => {
                return aggregateStateTotal(data);
            },
            dataType: 'state_deceased_organ_donor_overview',
            downloadableImage: true,
            chartTitle: 'State Overview - Deceased Organ Donor (YTD)',
            groupBy: 'groupedBy',
            geoJsonType: 'usStatesGeoJson',
            geoJsonIdField: 'properties.name',
            countField: 'total',
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    const result = aggregateStateCounts(data);
                    return {
                        data: result,
                        keys: ['Heart', 'Kidney', 'Liver', 'Lung', 'Pancreas'],
                    };
                }
            },
            chartTitle: '',
            width: '6',
            groupMode: 'stacked',
            layout: 'horizontal',
            withBrush: true,
            dataType: 'state_deceased_organ_donor_overview',
        },
        {
            type: 'map',
            width: 6,
            mapType: 'cloropleth',
            mapColor: 'blue',
            center: ['39.8283', '-98.5795'],
            zoom: '4',
            dataTransformer: (data: any, chart: any) => {
                return aggregateStateTotal(data);
            },
            dataType: 'state_living_organ_donor_overview',
            downloadableImage: true,
            chartTitle: 'State Overview - Living Organ Donor (YTD)',
            groupBy: 'id',
            geoJsonType: 'usStatesGeoJson',
            geoJsonIdField: 'properties.name',
            countField: 'value',
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    const result = aggregateStateCounts(data);
                    return {
                        data: result,
                        keys: ['Heart', 'Kidney', 'Liver', 'Lung', 'Pancreas'],
                    };
                }
            },
            chartTitle: '',
            width: '6',
            groupMode: 'stacked',
            layout: 'horizontal',
            withBrush: true,
            dataType: 'state_living_organ_donor_overview',
        },
        {
            type: 'map',
            width: 6,
            mapType: 'cloropleth',
            mapColor: 'blue',
            center: ['39.8283', '-98.5795'],
            zoom: '4',
            dataTransformer: (data: any, chart: any) => {
                return aggregateStateTotal(data);
            },
            dataType: 'state_waitlist_candidates_overview',
            downloadableImage: true,
            chartTitle: 'State Overview - Waitlist Candidates (YTD)',
            groupBy: 'id',
            geoJsonType: 'usStatesGeoJson',
            geoJsonIdField: 'properties.name',
            countField: 'value',
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    const result = aggregateStateCounts(data);
                    return {
                        data: result,
                        keys: ['Heart', 'Kidney', 'Liver', 'Lung', 'Pancreas'],
                    };
                }
            },
            chartTitle: '',
            width: '6',
            groupMode: 'stacked',
            layout: 'horizontal',
            withBrush: true,
            dataType: 'state_waitlist_candidates_overview',
        },
        {
            type: 'map',
            width: 6,
            mapType: 'cloropleth',
            mapColor: 'blue',
            center: ['39.8283', '-98.5795'],
            zoom: '4',
            dataTransformer: (data: any, chart: any) => {
                return aggregateStateTotal(data);
            },
            dataType: 'state_transplant_recipients_overview',
            downloadableImage: true,
            chartTitle: 'State Overview - Transplant Recipients (YTD)',
            groupBy: 'id',
            geoJsonType: 'usStatesGeoJson',
            geoJsonIdField: 'properties.name',
            countField: 'value',
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    const result = aggregateStateCounts(data);
                    return {
                        data: result,
                        keys: ['Heart', 'Kidney', 'Liver', 'Lung', 'Pancreas'],
                    };
                }
            },
            chartTitle: '',
            width: '6',
            groupMode: 'stacked',
            layout: 'horizontal',
            withBrush: true,
            dataType: 'state_transplant_recipients_overview',
        },
    ];
};

export const demographicsBuild = (setFilters: any, dashboard: any) => {
    return [
        {
            type: 'text',
            title: 'Recipient Demographics Overview',
            width: 12,
            height: 100,
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    let result: { groupedBy: any; male: any; female: any }[] = [];
                    data.items.map((item: any) => {
                        result.push({
                            groupedBy: `${item.organ} - ${item.donor_type}`,
                            male: item.male,
                            female: item.female,
                        });
                    });
                    return {
                        data: result,
                        keys: ['male', 'female'],
                    };
                }
            },
            chartTitle: 'Recipients by Organ and Sex',
            width: '12',
            groupMode: 'stacked',
            layout: 'horizontal',
            xAxisRotate: false,
            dataType: 'recipient_demographics_sex',
            colourScheme: ['#696969', '#FF69B4'],
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    let result: {
                        groupedBy: string;
                        '0-17': any;
                        '18-30': any;
                        '31-40': any;
                        '41-50': any;
                        '51-60': any;
                        age_not_reported: any;
                    }[] = [];
                    data.items.map((item: any) => {
                        result.push({
                            groupedBy: `${item.organ} - ${item.donor_type}`,
                            '0-17': item['0-17'],
                            '18-30': item['18-30'],
                            '31-40': item['31-40'],
                            '41-50': item['41-50'],
                            '51-60': item['51-60'],
                            age_not_reported: item['age_not_reported'],
                        });
                    });
                    return {
                        data: result,
                        keys: ['0-17', '18-30', '31-40', '41-50', '51-60', '60-100', 'age_not_reported'],
                    };
                }
            },
            chartTitle: 'Recipients by Organ and Age',
            width: '12',
            groupMode: 'stacked',
            layout: 'horizontal',
            xAxisRotate: false,
            dataType: 'recipient_demographics_age',
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    let result: {
                        groupedBy: string;
                        white: any;
                        black: any;
                        hispanic: any;
                        asian: any;
                        american_indian: any;
                        multiracial: any;
                    }[] = [];
                    data.items.map((item: any) => {
                        result.push({
                            groupedBy: `${item.organ} - ${item.donor_type}`,
                            white: item['white'],
                            black: item['black'],
                            hispanic: item['hispanic'],
                            asian: item['asian'],
                            american_indian: item['american_indian'],
                            multiracial: item['multiracial'],
                        });
                    });
                    return {
                        data: result,
                        keys: [
                            'white',
                            'black',
                            'hispanic',
                            'asian',
                            'american_indian',
                            'native_hawaiian',
                            'multiracial',
                        ],
                    };
                }
            },
            chartTitle: 'Recipients by Organ and Race/Ethnicity',
            width: '12',
            groupMode: 'stacked',
            layout: 'horizontal',
            xAxisRotate: false,
            dataType: 'recipient_demographics_race',
        },
        {
            type: 'divider',
        },
        {
            type: 'text',
            title: 'Donor Demographics Overview',
            width: 12,
            height: 100,
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { id: any; label: any; value: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        pieData.push({
                            id: item._id,
                            label: item.level,
                            value: item.donor_count,
                        });
                    });
                }
                return pieData;
            },
            chartTitle: 'Donor Type',
            width: '4',
            filterBy: 'level',
            dataType: 'donor_demographics_overview',
            colourScheme: ['#D64242', '#87CEEB'],
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { id: any; label: any; value: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        pieData.push({
                            id: item._id,
                            label: item.level,
                            value: item.donor_count,
                        });
                    });
                }
                return pieData;
            },
            chartTitle: 'Donor Sex',
            width: '4',
            filterBy: 'level',
            dataType: 'donor_demographics_sex',
            colourScheme: ['#696969', '#FF69B4'],
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { id: any; label: any; value: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        pieData.push({
                            id: item._id,
                            label: item.level,
                            value: item.donor_count,
                        });
                    });
                }
                return pieData;
            },
            chartTitle: 'Donor Race/Ethnicity',
            width: '4',
            filterBy: 'level',
            dataType: 'donor_demographics_race',
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { id: any; label: any; value: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        pieData.push({
                            id: item._id,
                            label: item.level,
                            value: item.donor_count,
                        });
                    });
                }
                return pieData;
            },
            chartTitle: 'Donor Type',
            width: '4',
            filterBy: 'level',
            dataType: 'donor_demographics_age',
        },
        {
            type: 'barChart',
            dataTransformer: (data: { items: any[] }) => {
                let chartData: { groupedBy: any; count: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        chartData.push({
                            groupedBy: item.level,
                            count: item.donor_count,
                        });
                    });
                }
                return {
                    data: chartData,
                    keys: ['count'],
                };
            },
            yAxisLabel: 'Donor Count',
            xAxisLabel: 'Year',
            chartTitle: 'Donor Counts by Year',
            width: '8',
            dataType: 'donor_demographics_year',
        },
    ];
};

export const opoOtcBuild = (setFilters: any, dashboard: any) => {
    return [
        {
            type: 'text',
            title: 'Organ Procurement Organizations (OPOs) Overview',
            width: 12,
            height: 80,
        },
        {
            type: 'map',
            width: 6,
            mapType: 'cloropleth',
            mapColor: 'blue',
            center: ['39.8283', '-98.5795'],
            zoom: '4',
            dataTransformer: (data: any, chart: any) => {
                return aggregateStateTotal(data);
            },
            dataType: 'opo_collection_by_state',
            downloadableImage: true,
            chartTitle: 'Organ Procurement Organizations (OPOs) by State',
            groupBy: 'id',
            geoJsonType: 'usStatesGeoJson',
            geoJsonIdField: 'properties.name',
            countField: 'value',
        },
        {
            type: 'tableComponent',
            width: 6,
            dataTransformer: (data: any, chart: any) => {
                let columns = chart.columns || [];
                const tableData: any[] = [];
                if (data && data.items && data.items.length > 0) {
                    let keyNames: string[] = [];
                    data.items.map((item: any, i: number) => {
                        const columnNames = Object.keys(item);
                        keyNames = Array.from(new Set([...keyNames, ...columnNames]));
                    });
                    const keys = keyNames.sort();
                    if (!chart.columns) {
                        keys.forEach((key) => {
                            columns = [
                                {
                                    name: 'opo_name',
                                    label: 'OPO Name',
                                },
                                {
                                    name: 'state',
                                    label: 'State',
                                },
                            ];
                        });
                    }

                    data.items.forEach((d: any) => {
                        const row = {
                            ...d,
                        };
                        if (chart.idField) {
                            row._id = d[chart.idField];
                        } else {
                            delete row._id;
                        }

                        tableData.push(row);
                    });
                }

                const returnData = {
                    columns,
                    data: tableData,
                };

                return returnData;
            },
            dataType: 'opo_collection',
            download: false,
            downloadableCsv: false,
            elevation: 0,
            filter: false,
            print: false,
            pagination: false,
            search: false,
            selectableRows: false,
            sort: true,
            rowsPerPageOptions: [],
            viewColumns: false,
            serverSide: true,
            rowClick: true,
            idField: '_id',
            showIdField: true,
            hideExport: true,
            height: 500,
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                let result = {};
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    data.items.forEach(
                        (item: {
                            _id: { opo_name: any; organ: any; procured_organ_transplant_rate: any };
                            organ: string | number;
                            type: string;
                            total: any;
                        }) => {
                            if (!result[item._id.opo_name]) {
                                result[item._id.opo_name] = {
                                    groupedBy: item._id.opo_name,
                                    heart: 0,
                                    kidney: 0,
                                    liver: 0,
                                    lung: 0,
                                    pancreas: 0,
                                };
                            }

                            if (item._id.organ === 'Heart') {
                                result[item._id.opo_name].heart = item._id.procured_organ_transplant_rate * 100;
                            } else if (item._id.organ === 'Kidney') {
                                result[item._id.opo_name].kidney = item._id.procured_organ_transplant_rate * 100;
                            } else if (item._id.organ === 'Liver') {
                                result[item._id.opo_name].liver = item._id.procured_organ_transplant_rate * 100;
                            } else if (item._id.organ === 'Lung') {
                                result[item._id.opo_name].lung = item._id.procured_organ_transplant_rate * 100;
                            } else if (item._id.organ === 'Pancreas') {
                                result[item._id.opo_name].pancreas = item._id.procured_organ_transplant_rate * 100;
                            }
                        },
                    );
                }
                let finalResult = Object.values(result);
                return {
                    data: finalResult,
                    keys: ['heart', 'kidney', 'liver', 'lung', 'pancreas'],
                };
            },
            chartTitle: 'OPO Transplant Rate by Organ (YTD By Percentage)',
            width: '12',
            groupMode: 'stacked',
            layout: 'horizontal',
            xAxisRotate: false,
            withBrush: true,
            dataType: 'opo_transplant_rate',
        },
        {
            type: 'divider',
        },
        {
            type: 'text',
            title: 'Organ Transplant Centers (OTCs) Overview',
            width: 12,
            height: 80,
        },
        {
            type: 'map',
            width: 6,
            mapType: 'cloropleth',
            mapColor: 'blue',
            center: ['39.8283', '-98.5795'],
            zoom: '4',
            dataTransformer: (data: any, chart: any) => {
                return aggregateStateTotal(data);
            },
            dataType: 'otc_collection_by_state',
            downloadableImage: true,
            chartTitle: 'Organ Transplant Centers (OTCs) by State',
            groupBy: 'id',
            geoJsonType: 'usStatesGeoJson',
            geoJsonIdField: 'properties.name',
            countField: 'value',
        },
        {
            type: 'tableComponent',
            width: 6,
            dataTransformer: (data: any, chart: any) => {
                let columns = chart.columns || [];
                const tableData: any[] = [];
                if (data && data.items && data.items.length > 0) {
                    let keyNames: string[] = [];
                    data.items.map((item: any, i: number) => {
                        const columnNames = Object.keys(item);
                        keyNames = Array.from(new Set([...keyNames, ...columnNames]));
                    });
                    const keys = keyNames.sort();
                    if (!chart.columns) {
                        keys.forEach((key) => {
                            columns = [
                                {
                                    name: 'transplant_center',
                                    label: 'Transplant Center',
                                },
                                {
                                    name: 'state',
                                    label: 'State',
                                },
                            ];
                        });
                    }

                    data.items.forEach((d: any) => {
                        const row = {
                            ...d,
                        };
                        if (chart.idField) {
                            row._id = d[chart.idField];
                        } else {
                            delete row._id;
                        }

                        tableData.push(row);
                    });
                }

                const returnData = {
                    columns,
                    data: tableData,
                };

                return returnData;
            },
            dataType: 'otc_collection',
            download: false,
            downloadableCsv: false,
            elevation: 0,
            filter: false,
            print: false,
            pagination: false,
            search: false,
            selectableRows: false,
            sort: false,
            rowsPerPageOptions: [],
            viewColumns: false,
            serverSide: true,
            rowClick: true,
            idField: '_id',
            showIdField: true,
            hideExport: true,
            height: 500,
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                let result = {};
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    data.items.forEach(
                        (item: {
                            _id: {
                                deceased_donor_transplant_month: number;
                                deceased_donor_transplant_year: number;
                                deceased_donor_transplant_two_years: number;
                                deceased_donor_transplant_three_years: number;
                                transplant_center: any;
                                organ: any;
                                procured_organ_transplant_rate: any;
                            };
                            organ: string | number;
                            type: string;
                            total: any;
                        }) => {
                            result[item._id.transplant_center] = {
                                groupedBy: item._id.transplant_center,
                                'Percent of Deceased Donor Transplant with 30 Days':
                                    item._id.deceased_donor_transplant_month * 100,
                                'Percent of Deceased Donor Transplant with 1 Year':
                                    item._id.deceased_donor_transplant_year * 100,
                                'Percent of Deceased Donor Transplant with 2 Years':
                                    item._id.deceased_donor_transplant_two_years * 100,
                                'Percent of Deceased Donor Transplant with 3 Years':
                                    item._id.deceased_donor_transplant_three_years * 100,
                            };
                        },
                    );
                }

                let finalResult = Object.values(result);
                return {
                    data: finalResult,
                    keys: [
                        'Percent of Deceased Donor Transplant with 30 Days',
                        'Percent of Deceased Donor Transplant with 1 Year',
                        'Percent of Deceased Donor Transplant with 2 Years',
                        'Percent of Deceased Donor Transplant with 3 Years',
                    ],
                };
            },
            chartTitle: 'OTC Transplant Rate for All Organs (All-Time By Percentage)',
            width: '12',
            groupMode: 'stacked',
            layout: 'horizontal',
            xAxisRotate: false,
            withBrush: true,
            dataType: 'otc_transplant_rate',
        },
    ];
};

export const waitlistBuild = (setFilters: any, dashboard: any) => {
    return [
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { id: any; label: any; value: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        pieData.push({
                            id: item._id,
                            label: item.type,
                            value: item.total,
                        });
                    });
                }
                return pieData;
            },
            chartTitle: 'Candidates and Registration (YTD)',
            width: '4',
            dataType: 'candidates_registrations',
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                let result = {};
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    data.items.forEach((item: { organ: string | number; type: string; total: any }) => {
                        if (!result[item.organ]) {
                            result[item.organ] = { groupedBy: item.organ, candidates: 0, registrations: 0 };
                        }

                        if (item.type === 'Candidates') {
                            result[item.organ].candidates = item.total;
                        } else if (item.type === 'Registrations') {
                            result[item.organ].registrations = item.total;
                        }
                    });
                }
                let finalResult = Object.values(result);
                return {
                    data: finalResult,
                    keys: ['candidates', 'registrations'],
                };
            },
            chartTitle: 'Candidates and Registrations by Organ (YTD)',
            width: '8',
            groupMode: 'stacked',
            layout: 'horizontal',
            xAxisRotate: true,
            dataType: 'candidates_registrations_organ',
            colourScheme: ['#696969', '#FF69B4'],
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { label: any; value: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        pieData.push(
                            {
                                label: `female`,
                                value: item.female,
                            },
                            {
                                label: `male`,
                                value: item.male,
                            },
                        );
                    });
                }
                return pieData;
            },
            chartTitle: 'Candidates by Sex (YTD)',
            width: '4',
            dataType: 'waitlist_sex',
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                let result = {};
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    data.items.forEach(
                        (item: { female: any; male: any; organ: string | number; type: string; total: any }) => {
                            if (!result[item.organ]) {
                                result[item.organ] = {
                                    groupedBy: item.organ,
                                    male: 0,
                                    female: 0,
                                };
                            }

                            result[item.organ]['male'] = item.male;
                            result[item.organ]['female'] = item.female;
                        },
                    );
                }
                let finalResult = Object.values(result);
                return {
                    data: finalResult,
                    keys: ['male', 'female'],
                };
            },
            chartTitle: 'Candidates by Sex and Organ (YTD)',
            width: '8',
            groupMode: 'stacked',
            layout: 'horizontal',
            xAxisRotate: true,
            dataType: 'waitlist_sex_organ',
            colourScheme: ['#696969', '#FF69B4'],
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { label: any; value: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        pieData.push(
                            {
                                label: `White, Non-Hispanic`,
                                value: item.white,
                            },
                            {
                                label: `Black, Non-Hispanic`,
                                value: item.black,
                            },
                            {
                                label: `Hispanic/Latino`,
                                value: item.hispanic,
                            },
                            {
                                label: `Asian, Non-Hispanic`,
                                value: item.asian,
                            },
                            {
                                label: `Amer Ind/Alaska Native, Non-Hispanic`,
                                value: item.american_indian,
                            },
                            {
                                label: `Native Hawaiian/other Pacific Islander, Non-Hispanic`,
                                value: item.native_hawaiian,
                            },
                            {
                                label: `Multiracial, Non-Hispanic`,
                                value: item.multiracial,
                            },
                        );
                    });
                }
                return pieData;
            },
            chartTitle: 'Candidates by Ethnicity/Race (YTD)',
            width: '4',
            dataType: 'waitlist_demographics',
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                let result = {};
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    data.items.forEach(
                        (item: {
                            multiracial: any;
                            native_hawaiian: any;
                            american_indian: any;
                            asian: any;
                            hispanic: any;
                            black: any;
                            white: any;
                            organ: string | number;
                            type: string;
                            total: any;
                        }) => {
                            if (!result[item.organ]) {
                                result[item.organ] = {
                                    groupedBy: item.organ,
                                    'White, Non-Hispanic': 0,
                                    'Black, Non-Hispanic': 0,
                                    'Hispanic/Latino': 0,
                                    'Asian, Non-Hispanic': 0,
                                    'Amer Ind/Alaska Native, Non-Hispanic': 0,
                                    'Native Hawaiian/other Pacific Islander, Non-Hispanic': 0,
                                    'Multiracial, Non-Hispanic': 0,
                                };
                            }
                            result[item.organ]['White, Non-Hispanic'] = item.white;
                            result[item.organ]['Black, Non-Hispanic'] = item.black;
                            result[item.organ]['Hispanic/Latino'] = item.hispanic;
                            result[item.organ]['Asian, Non-Hispanic'] = item.asian;
                            result[item.organ]['Amer Ind/Alaska Native, Non-Hispanic'] = item.american_indian;
                            result[item.organ]['Native Hawaiian/other Pacific Islander, Non-Hispanic'] =
                                item.native_hawaiian;
                            result[item.organ]['Multiracial, Non-Hispanic'] = item.multiracial;
                        },
                    );
                }
                let finalResult = Object.values(result);
                return {
                    data: finalResult,
                    keys: [
                        'White, Non-Hispanic',
                        'Black, Non-Hispanic',
                        'Hispanic/Latino',
                        'Asian, Non-Hispanic',
                        'Amer Ind/Alaska Native, Non-Hispanic',
                        'Native Hawaiian/other Pacific Islander, Non-Hispanic',
                        'Multiracial, Non-Hispanic',
                    ],
                };
            },
            chartTitle: 'Candidates by Ethnicity/Race and Organ (YTD)',
            width: '8',
            groupMode: 'stacked',
            layout: 'horizontal',
            xAxisRotate: true,
            dataType: 'waitlist_demographics_organ',
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { label: any; value: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        pieData.push(
                            {
                                label: `Pediatric`,
                                value: item['0-17'],
                            },
                            {
                                label: `18-30`,
                                value: item['18-30'],
                            },
                            {
                                label: `31-40`,
                                value: item['31-40'],
                            },
                            {
                                label: `41-50`,
                                value: item['41-50'],
                            },
                            {
                                label: `51-60`,
                                value: item['51-60'],
                            },
                            {
                                label: `61+`,
                                value: item['61-100'],
                            },
                        );
                    });
                }
                return pieData;
            },
            chartTitle: 'Candidates by Age Group (YTD)',
            width: '4',
            dataType: 'waitlist_age',
        },
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                let result = {};
                if ((data && data.items && data.items.length === 0) || !data) return 0;
                if (data && data.items && data.items.length) {
                    data.items.forEach(
                        (item: {
                            multiracial: any;
                            native_hawaiian: any;
                            american_indian: any;
                            asian: any;
                            hispanic: any;
                            black: any;
                            white: any;
                            organ: string | number;
                            type: string;
                            total: any;
                        }) => {
                            if (!result[item.organ]) {
                                result[item.organ] = {
                                    groupedBy: item.organ,
                                    Pediatric: 0,
                                    '18-30': 0,
                                    '31-40': 0,
                                    '41-50': 0,
                                    '51-60': 0,
                                    '61+': 0,
                                };
                            }
                            result[item.organ]['Pediatric'] = item['0-17'];
                            result[item.organ]['18-30'] = item['18-30'];
                            result[item.organ]['31-40'] = item['31-40'];
                            result[item.organ]['41-50'] = item['41-50'];
                            result[item.organ]['51-60'] = item['51-60'];
                            result[item.organ]['61+'] = item['61-100'];
                        },
                    );
                }
                let finalResult = Object.values(result);
                return {
                    data: finalResult,
                    keys: ['Pediatric', '18-30', '31-40', '41-50', '51-60', '61+'],
                };
            },
            chartTitle: 'Candidates by Age Group and Organ (YTD)',
            width: '8',
            groupMode: 'stacked',
            layout: 'horizontal',
            xAxisRotate: true,
            dataType: 'waitlist_age_organ',
        },
    ];
};
