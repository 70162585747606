import financialIcon from '../../../../../../assets/images/icons8-financial-96.png';
import awardsIcon from '../../../../../../assets/images/icons8-awards-96.png';
export const dashboardBuild = (setFilters: any, dashboard: any) => {
    return [
        {
            type: 'iconCount',
            width: 6,
            count: {
                title: 'Total Grants Awarded',
                color: '#fff !important',
                primaryTextColor: '#0b0b0b !important',
                secondaryTextColor: '#E8C658 !important',
                paperElevation: 1,
                image: awardsIcon,
            },
            dataTransformer: (data: any, chart: any) => {
                if (data && data.items && data.items.length === 0) return 0;
                if (data && data.items && data.items.length) {
                    const grantTotal = data.items[0]['COUNT'];
                    return grantTotal.toLocaleString();
                }
            },
            dataType: 'overview_grant',
            includeChartTitle: false,
            height: 150,
        },
        {
            type: 'iconCount',
            width: 6,
            count: {
                title: 'Total Financial Assistance Provided',
                color: '#fff !important',
                primaryTextColor: '#0b0b0b !important',
                secondaryTextColor: '#E8C658 !important',
                paperElevation: 1,
                image: financialIcon,
            },
            dataTransformer: (data: any, chart: any) => {
                if (data && data.items && data.items.length === 0) return 0;
                if (data && data.items && data.items.length) {
                    const totalFinancialAssistance = data.items.reduce((total: any, item: { [x: string]: any }) => {
                        return total + (item._id['Financial Assistance'] || 0);
                    }, 0);
                    return `$${totalFinancialAssistance.toLocaleString()}`;
                }
            },
            dataType: 'amount_by_year',
            includeChartTitle: false,
            height: 150,
        },
        {
            type: 'barChart',
            dataTransformer: (data: { items: any[] }) => {
                let chartData: { groupedBy: any; count: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        chartData.push({
                            groupedBy: item['Award Year'],
                            count: item['COUNT'],
                        });
                    });
                }
                const sortedData = chartData.sort((a, b) => a.groupedBy - b.groupedBy);
                return {
                    data: sortedData,
                    keys: ['count'],
                };
            },
            yAxisLabel: 'Amount Awarded',
            xAxisLabel: 'Year',
            chartTitle: 'Number of Awarded Grants by Year',
            width: 6,
            dataType: 'count_by_year',
            showBarValue: false,
            filterBy: 'Award Year',
            // aggregatedDataFilter: true,
            // customFilterAggregation: (data: any, item: string) => {
            //     console.log('DATA', data, item);
            //     const filter = {
            //         ['Award Year']: {
            //             id: 'Award Year',
            //             type: 'NUMERIC',
            //             eq: data,
            //         },
            //     };
            //     setFilters(filter);
            // },
            // showYLabels: false,
        },
        {
            type: 'barChart',
            dataTransformer: (data: { items: any[] }) => {
                let chartData: { groupedBy: any; count: any }[] = [];
                if (data && data.items && data.items.length) {
                    const groupedTotals = Object.values(
                        data.items.reduce((acc, item) => {
                            const year = item._id['Award Year'];
                            const financialAssistance = item._id['Financial Assistance'] || 0;

                            // Initialize the year entry if it doesn't exist
                            if (!acc[year]) {
                                acc[year] = { year: year, count: 0 };
                            }

                            // Accumulate the financial assistance for the given year
                            acc[year].count += financialAssistance;
                            return acc;
                        }, {}),
                    );
                    groupedTotals.map((item: any) => {
                        chartData.push({
                            groupedBy: item.year,
                            count: item.count.toFixed(2),
                        });
                    });
                }
                return {
                    data: chartData,
                    keys: ['count'],
                };
            },
            yAxisLabel: 'Amount Awarded',
            xAxisLabel: 'Year',
            chartTitle: 'Awarded Financial Amount by Year',
            width: 6,
            dataType: 'amount_by_year',
            showBarValue: false,
            startAdornment: '$',
            filterBy: 'Award Year',
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { id: any; label: any; value: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        pieData.push({
                            id: item._id,
                            label: item['HRSA Program Area Name'],
                            value: item['COUNT'],
                        });
                    });
                }
                return pieData;
            },
            chartTitle: 'Number of Awarded Grants by HRSA Program Area',
            width: '6',
            dataType: 'count_by_program',
            filterBy: 'HRSA Program Area Name',
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { id: any; label: any; value: any }[] = [];

                if (data && data.items && data.items.length) {
                    // Aggregate data by program name
                    const groupedTotals = Object.values(
                        data.items.reduce((acc, item) => {
                            const program = item._id['HRSA Program Area Name'];
                            const financialAssistance = item._id['Financial Assistance'] || 0;

                            // Initialize the program entry if it doesn't exist
                            if (!acc[program]) {
                                acc[program] = { program: program, count: 0 };
                            }

                            // Accumulate the financial assistance for the given program
                            acc[program].count += financialAssistance;
                            return acc;
                        }, {}),
                    );

                    // Transform groupedTotals to match pieData format
                    groupedTotals.forEach((item: any) => {
                        pieData.push({
                            id: item.program,
                            label: item.program,
                            value: item.count,
                        });
                    });
                }
                return pieData;
            },
            chartTitle: 'Awarded Financial Amount by HRSA Program Area',
            width: '6',
            filterBy: 'HRSA Program Area Name',
            dataType: 'amount_by_program',
        },
        {
            type: 'barChart',
            dataTransformer: (data: { items: any[] }) => {
                let chartData: { groupedBy: any; count: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        chartData.push({
                            groupedBy: item['Grant Program Name'],
                            count: item['COUNT'],
                        });
                    });
                }
                const sortedData = chartData.sort((a, b) => a.groupedBy - b.groupedBy);
                return {
                    data: sortedData,
                    keys: ['count'],
                };
            },
            yAxisLabel: 'Amount Awarded',
            xAxisLabel: 'Year',
            chartTitle: 'Number of Awarded Grants by Category',
            width: 6,
            dataType: 'count_by_category',
            showBarValue: false,
            withBrush: true,
            showBrush: true,
            groupBy: 'Grant Program Name',
            layout: 'horizontal',
            filterBy: 'Grant Program Name',
            tickAmount: 5,
            xAxisRotate: false,
        },
        {
            type: 'barChart',
            dataTransformer: (data: { items: any[] }) => {
                let chartData: { groupedBy: any; count: any }[] = [];
                if (data && data.items && data.items.length) {
                    const groupedTotals = Object.values(
                        data.items.reduce((acc, item) => {
                            const year = item._id['Grant Program Name'];
                            const financialAssistance = item._id['Financial Assistance'] || 0;

                            // Initialize the year entry if it doesn't exist
                            if (!acc[year]) {
                                acc[year] = { year: year, count: 0 };
                            }

                            // Accumulate the financial assistance for the given year
                            acc[year].count += financialAssistance;
                            return acc;
                        }, {}),
                    );
                    groupedTotals.map((item: any) => {
                        chartData.push({
                            groupedBy: item.year,
                            count: item.count.toFixed(2),
                        });
                    });
                }
                const sortedData = chartData.sort((a, b) => b.count - a.count);
                return {
                    data: sortedData,
                    keys: ['count'],
                };
            },
            yAxisLabel: 'Amount Awarded',
            xAxisLabel: 'Year',
            chartTitle: 'Awarded Financial Amount by Category',
            width: 6,
            dataType: 'amount_by_category',
            showBarValue: false,
            startAdornment: '$',
            layout: 'horizontal',
            withBrush: true,
            filterBy: 'Grant Program Name',
            tickAmount: 3,
            xAxisRotate: false,
            // showYLabels: false,
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { id: any; label: any; value: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        pieData.push({
                            id: item._id,
                            label: item['Grantee Type Description'],
                            value: item['COUNT'],
                        });
                    });
                }
                return pieData;
            },
            chartTitle: 'Number of Awarded Grants by Grantee Type',
            width: '6',
            dataType: 'count_by_grantee_type',
            filterBy: 'Grantee Type Description',
        },
        {
            type: 'pieChart',
            dataTransformer: (data: { items: any[] }) => {
                let pieData: { id: any; label: any; value: any }[] = [];

                if (data && data.items && data.items.length) {
                    // Aggregate data by program name
                    const groupedTotals = Object.values(
                        data.items.reduce((acc, item) => {
                            const program = item._id['Grantee Type Description'];
                            const financialAssistance = item._id['Financial Assistance'] || 0;

                            // Initialize the program entry if it doesn't exist
                            if (!acc[program]) {
                                acc[program] = { program: program, count: 0 };
                            }

                            // Accumulate the financial assistance for the given program
                            acc[program].count += financialAssistance;
                            return acc;
                        }, {}),
                    );

                    // Transform groupedTotals to match pieData format
                    groupedTotals.forEach((item: any) => {
                        pieData.push({
                            id: item.program,
                            label: item.program,
                            value: item.count,
                        });
                    });
                }
                return pieData;
            },
            chartTitle: 'Awarded Financial Amount by Grantee Type',
            width: '6',
            filterBy: 'Grantee Type Description',
            dataType: 'amount_by_grantee_type',
            startAdornment: '$',
        },
    ];
};

export const regionsBuild = (setFilters: any, dashboard: any) => {
    console.log('DASHBOARD', dashboard);
    return [
        {
            type: 'barChart',
            dataTransformer: (data: any, chart: any) => {
                if (!data) {
                    return {
                        data: [],
                        keys: [],
                    };
                }
                if (data && data.items && data.items.length) {
                    const states = Array.from(
                        new Set(data.items.map((item: { _id: { [x: string]: any } }) => item._id['State Name'])),
                    );

                    const transformedData = data.items.reduce(
                        (
                            acc: any[],
                            item: {
                                _id: { [x: string]: any };
                                COUNT: any;
                            },
                        ) => {
                            const year = item._id['Award Year'];
                            const state = item._id['State Name'];
                            const count = item.COUNT;

                            let yearEntry = acc.find((entry) => entry.groupedBy === year);
                            if (!yearEntry) {
                                // Initialize year entry with all states set to 0 or null
                                yearEntry = { groupedBy: year, ...Object.fromEntries(states.map((s) => [s, 0])) };
                                acc.push(yearEntry);
                            }

                            // Set the count for the specific state
                            yearEntry[state] = count;

                            return acc;
                        },
                        [],
                    );
                    transformedData.sort(
                        (a: { groupedBy: number }, b: { groupedBy: number }) => a.groupedBy - b.groupedBy,
                    );
                    return {
                        data: transformedData,
                        keys: states,
                    };
                }
            },
            chartTitle: 'Number of Awards Granted by State and Year',
            width: '12',
            groupMode: 'stacked',
            layout: 'horizontal',
            xAxisRotate: true,
            dataType: 'state_grant_by_year',
            showBarValue: false,
            filterBy: 'State Name',
        },
        // {
        //     type: 'barChart',
        //     dataTransformer: (data: any, chart: any) => {
        //         if (!data) {
        //             return {
        //                 data: [],
        //                 keys: [],
        //             }
        //         }
        //         if (data && data.items && data.items.length) {
        //             const states = Array.from(new Set(data.items.map((item: {
        //                 _id: { [x: string]: any; };
        //             }) => item._id["State Name"])));
        //
        //             const transformedData = data.items.reduce((acc: any[], item: {
        //                 _id: { [x: string]: any; };
        //                 COUNT: any;
        //             }) => {
        //                 const year = item._id["Financial Assistance"];
        //                 const state = item._id["State Name"];
        //                 const count = item.COUNT;
        //
        //                 let yearEntry = acc.find(entry => entry.groupedBy === year);
        //                 if (!yearEntry) {
        //                     // Initialize year entry with all states set to 0 or null
        //                     yearEntry = {groupedBy: year, ...Object.fromEntries(states.map(s => [s, 0]))};
        //                     acc.push(yearEntry);
        //                 }
        //
        //                 // Set the count for the specific state
        //                 yearEntry[state] = count;
        //
        //                 return acc;
        //             }, []);
        //             transformedData.sort((a: { groupedBy: number; }, b: { groupedBy: number; }) => a.groupedBy - b.groupedBy);
        //             return {
        //                 data: transformedData,
        //                 keys: states,
        //             };
        //         }
        //     },
        //     chartTitle: 'Total Financial Amount Granted by State and Year',
        //     width: '12',
        //     groupMode: 'stacked',
        //     layout: 'horizontal',
        //     xAxisRotate: true,
        //     dataType: 'state_grant_by_amount',
        //     showBarValue: false,
        // },
        {
            type: 'map',
            width: 6,
            mapType: 'cloropleth',
            mapColor: 'blue',
            center: ['39.8283', '-98.5795'],
            zoom: '4',
            dataTransformer: (data: any, chart: any) => {
                let aggregatedData: { id: any; value: any }[] = [];
                if (data && data.items && data.items.length === 0) return [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        aggregatedData.push({
                            id: item._id,
                            value: item.COUNT,
                        });
                    });
                    return aggregatedData;
                }
            },
            dataType: 'state_grant',
            downloadableImage: true,
            chartTitle: 'Number of Awards Granted by State',
            groupBy: 'id',
            geoJsonType: 'usStatesGeoJson',
            geoJsonIdField: 'properties.name',
            countField: 'value',
        },
        {
            type: 'barChart',
            dataTransformer: (data: { items: any[] }) => {
                let chartData: { groupedBy: any; count: any }[] = [];
                if (data && data.items && data.items.length) {
                    data.items.map((item: any) => {
                        chartData.push({
                            groupedBy: item['State Name'],
                            count: item['COUNT'],
                        });
                    });
                }
                const sortedData = chartData.sort((a, b) => a.groupedBy - b.groupedBy);
                return {
                    data: sortedData,
                    keys: ['count'],
                };
            },
            chartTitle: 'Number of Awarded Grants by State',
            width: 6,
            yAxisLabel: 'Amount Awarded',
            xAxisLabel: 'State Name',
            dataType: 'state_grant',
            showBarValue: false,
            withBrush: true,
            showBrush: true,
            groupBy: 'State Name',
            layout: 'horizontal',
            filterBy: 'State Name',
        },
    ];
};

export const tableBuild = (setFilters: any, dashboard: any) => {
    return [
        {
            type: 'tableComponent',
            width: 12,
            dataTransformer: (data: any, chart: any) => {
                let columns = chart.columns || [];
                const tableData: any[] = [];
                if (data && data.items && data.items.length > 0) {
                    let keyNames: string[] = [];
                    data.items.map((item: any, i: number) => {
                        const columnNames = Object.keys(item);
                        keyNames = Array.from(new Set([...keyNames, ...columnNames]));
                    });
                    const keys = keyNames.sort();
                    if (!chart.columns) {
                        keys.forEach((key) => {
                            columns = [
                                {
                                    name: '_id',
                                    label: 'ID',
                                    options: {
                                        display: false,
                                    },
                                },
                                {
                                    name: 'Project Period Start Date Text String',
                                    label: 'Start Date',
                                },
                                {
                                    name: 'Grant Project Period End Date Text',
                                    label: 'End Date',
                                },
                                {
                                    name: 'Financial Assistance',
                                    label: 'Financial Assistance',
                                },
                                {
                                    name: 'Grantee Name',
                                    label: 'Grantee Name',
                                    options: {
                                        sort: false,
                                    },
                                },
                                {
                                    name: 'Grantee Type Description',
                                    label: 'Grantee Type',
                                    options: {
                                        sort: false,
                                    },
                                },
                                {
                                    name: 'State Name',
                                    label: 'State',
                                    sort: false,
                                },
                                {
                                    name: 'HRSA Program Area Name',
                                    label: 'HRSA Program Area',
                                    sort: false,
                                },
                                {
                                    name: 'Grant Program Name',
                                    label: 'Grant Program',
                                    sort: false,
                                },
                            ];
                        });
                    }

                    data.items.forEach((d: any) => {
                        const row = {
                            ...d,
                        };

                        if (chart.conversions) {
                            Object.keys(chart.conversions).forEach((k) => {
                                row[k] = chart.conversions[k](d[k]);
                            });
                        }

                        if (chart.extraColumns) {
                            Object.keys(chart.extraColumns).forEach((k) => {
                                row[k] = chart.extraColumns[k].content(d);
                            });
                        }

                        if (chart.idField) {
                            row._id = d[chart.idField];
                        } else {
                            delete row._id;
                        }

                        tableData.push(row);
                    });
                }

                return {
                    columns,
                    data: tableData,
                };
            },
            chartTitle: 'Grants Awarded List',
            dataType: 'grant_list',
            download: false,
            downloadableCsv: true,
            elevation: 0,
            filter: false,
            print: false,
            pagination: true,
            search: false,
            selectableRows: false,
            sort: true,
            rowsPerPageOptions: window.screen.width < 700 ? [] : [10, 25, 50],
            viewColumns: false,
            serverSide: true,
            rowClick: true,
            idField: '_id',
            showIdField: true,
            hideExport: true,
            height: 600,
            conversions: {
                ['Financial Assistance']: (d: string) => {
                    return `$${d.toLocaleString()}`;
                },
            },
        },
    ];
};
