import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import { Box, Chip, Divider, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import StartIcon from '@mui/icons-material/Start';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(to right bottom, #008577 40%, #00857799) !important',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(to right bottom, #008577 40%, #00857799) !important',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage: 'linear-gradient(to right bottom, #008577 40%, #00857799) !important',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage: 'linear-gradient(to right bottom, #008577 40%, #00857799) !important',
    }),
}));

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <StartIcon />,
        2: <BookmarkIcon />,
        3: <SportsScoreIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}
// @ts-ignore
const TrialDetailDialog = ({ open, setOpen, selectedTrial, setSelectedTrial }) => {
    const [activeStep, setActiveStep] = useState<any>(0);
    const trialPubMed = useSelector((state: any) => state.dashboard && state.dashboard.selectedTrialPubMed);

    useEffect(() => {
        const now = new Date();
        const date = new Date(now);
        if (selectedTrial) {
            if (selectedTrial[5] !== 'N/A' && new Date(selectedTrial[5]) < date) {
                setActiveStep(2);
            } else if (selectedTrial[4] !== 'N/A' && new Date(selectedTrial[4]) < date) {
                setActiveStep(1);
            } else {
                setActiveStep(0);
            }
        }
    }, [selectedTrial]);

    const handleClose = () => {
        setOpen(false);
        setSelectedTrial(null);
    };

    const handleViewMore = () => {
        window.open(`https://www.clinicaltrials.gov/study/${selectedTrial[1]}`, '_blank');
    };

    const columns = [
        {
            name: 'title',
            label: 'Article Title',
            options: {
                customBodyRender: (value: any) => {
                    if (typeof value === 'object') {
                        return value['#text'] || '';
                    } else {
                        return value;
                    }
                },
            },
        },
        {
            name: 'PMID',
            label: 'PMID',
        },
        {
            name: 'pubDate',
            label: 'Publication Date',
            options: {
                sort: false,
                customBodyRender: (value: any) => {
                    return new Date(value).toLocaleDateString();
                },
            },
        },
        {
            name: 'journal',
            label: 'Journal',
        },
        {
            name: 'authors',
            label: 'Authors',
            sort: false,
            options: {
                customBodyRender: (value: any) => {
                    if (value && value.length > 0) {
                        return value[0].name;
                    }
                    return '';
                },
            },
        },
    ];

    const options = {
        search: false,
        filter: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        selectableRows: false,
        selectableRowsHeader: false,
        onRowClick: (rowData: any, rowMeta: any) => {
            const article = trialPubMed.items[rowMeta.dataIndex];
            window.open(`https://pubmed.ncbi.nlm.nih.gov/${article.PMID}`, '_blank');
        },
        textLabels: {
            body: {
                noMatch: 'Sorry, no pubmed articles found for this trial',
            },
        },
    };

    if (!selectedTrial) return null;
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth={'lg'}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={2}>
                        <Chip label={selectedTrial[1]} color={'primary'} style={{ fontWeight: 'bold' }} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant={'h5'} style={{ textAlign: 'center' }}>
                            {selectedTrial[0]}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Chip label={selectedTrial[2]} style={{ fontWeight: 'bold', float: 'right' }} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}>
                    <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                        {[selectedTrial[3], selectedTrial[4], selectedTrial[5]].map((label, i) => (
                            <Step key={label}>
                                <StepLabel
                                    StepIconComponent={ColorlibStepIcon}
                                    optional={
                                        i === 0 ? 'Start Date' : i === 1 ? 'Primary Completion Date' : 'Completion Date'
                                    }
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <DialogContentText id="alert-dialog-slide-description" style={{ padding: 10 }}>
                    <Typography variant={'h6'} style={{ color: '#000' }}>
                        Summary
                    </Typography>
                    <Divider />
                    {selectedTrial[10]}
                </DialogContentText>
                <DialogContentText id="alert-dialog-slide-description" style={{ padding: 10 }}>
                    <Typography variant={'h6'} style={{ color: '#000' }}>
                        Conditions
                    </Typography>
                    <Divider />
                    {selectedTrial[11].join(', ')}
                </DialogContentText>
                <MUIDataTable
                    title={'PubMed Articles'}
                    data={trialPubMed && trialPubMed.items}
                    columns={columns}
                    // @ts-ignore
                    options={options}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button variant={'contained'} onClick={handleViewMore}>
                    View on clinicaltrials.gov
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TrialDetailDialog;
