import React, { useEffect, createContext, useState, PropsWithChildren, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '../components/Dashboard/DashboardActions';

export interface IAppContextProps {
    currentPath?: string;
    handleClickOpenContact?: any;
    handleCloseContact?: () => void;
    setContactAnchorEl?: any;
    contactAnchorEl?: HTMLButtonElement;
    contactRef?: any;
    selectedDashboard: string;
    setSelectedDashboard: (value: string) => void;
}
export interface IAppProps {
    [key: string]: any;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AppContext = createContext<IAppContextProps>(undefined!);
const AppProviderWrapper: React.FC<PropsWithChildren<IAppProps>> = ({ children }) => {
    const [currentPath, setCurrentPath] = useState<string>('');
    const [selectedDashboard, setSelectedDashboard] = useState<string>('Overview');
    const [, setContactAnchorEl] = useState<HTMLButtonElement | null>(null);
    const location = useLocation();
    const contactRef = useRef();
    const dashboard = useSelector((state: any) => state.dashboard);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentPath !== location) {
            setCurrentPath(location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (dashboard && !dashboard.loadingCoordinates && !dashboard.coordinates.length) {
            ['91b64758d3f8d80b26ee', 'f0fc0ebc8cc686c06a2b', '1935c2da4f16c987f556'].map((id: string) => {
                // @ts-ignore
                dispatch(dashboardActions.getWorldCoordsData(id));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard]);

    // const handleClickOpenContact = () => {
    //     setContactAnchorEl(contactRef.current);
    // };

    // const handleCloseContact = () => {
    //     setContactAnchorEl(null);
    // };

    return (
        <AppContext.Provider
            value={{
                currentPath,
                // contactAnchorEl,
                setContactAnchorEl,
                contactRef,
                selectedDashboard,
                setSelectedDashboard,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProviderWrapper;
