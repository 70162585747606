import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, ActionCreatorsMapObject } from 'redux';
import { IConfirmationState } from './ConfirmationModel';
import * as confirmActions from './ConfirmationActions';
import { dashboardActions } from '../Dashboard/DashboardActions';
import { IAppStore } from '../Shared/Redux';

export interface IConfirmationProps {
    confirmation?: IConfirmationState;
    actions: ActionCreatorsMapObject;
    dashboardActions: ActionCreatorsMapObject;
}

export const ConfirmationDialog: React.FC<IConfirmationProps> = ({ confirmation, actions, dashboardActions }) => {
    const handleCancel = (e: SyntheticEvent) => {
        if (e) {
            e.preventDefault();
        }
        actions.hideConfirmation();
    };
    const handleOk = (e: SyntheticEvent) => {
        if (e) {
            e.preventDefault();
        }

        if (confirmation && confirmation.confirmationType === 'removeFilter') {
            dashboardActions.setFilters(confirmation.param1);
        }

        actions.hideConfirmation();
    };

    return (
        <Dialog
            open={!!(confirmation && confirmation.visible)}
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
        >
            <DialogTitle id="confirmation-dialog-title">{confirmation && confirmation.title}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{confirmation && confirmation.message}</Typography>
            </DialogContent>
            <DialogActions>
                <div>
                    <Button onClick={handleCancel} color="primary" style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleOk} color="primary">
                        Ok
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

function mapStateToProps(store: IAppStore) {
    return {
        confirmation: store.confirmation,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        actions: bindActionCreators(confirmActions.actions, dispatch),
        dashboardActions: bindActionCreators(dashboardActions, dispatch),
    };
}

// middleware
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog);
