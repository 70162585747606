export const queries = {
    overview_grant: {
        addCount: true,
        fields: ['Award Year'],
        initialFilter: {},
    },
    amount_by_year: {
        limit: 0,
        groupBy: ['Award Year', 'Financial Assistance'],
    },
    count_by_year: {
        addCount: true,
        limit: 0,
        groupBy: ['Award Year'],
    },
    amount_by_category: {
        limit: 0,
        groupBy: ['Grant Program Name', 'Financial Assistance'],
    },
    count_by_category: {
        addCount: true,
        limit: 0,
        groupBy: ['Grant Program Name'],
        sortAsc: false,
        sortField: 'COUNT',
    },
    count_by_program: {
        addCount: true,
        limit: 0,
        groupBy: ['HRSA Program Area Name'],
        sortAsc: false,
        sortField: 'COUNT',
    },
    amount_by_program: {
        limit: 0,
        groupBy: ['HRSA Program Area Name', 'Financial Assistance'],
    },
    count_by_grantee_type: {
        addCount: true,
        limit: 0,
        groupBy: ['Grantee Type Description'],
        sortAsc: false,
        sortField: 'COUNT',
    },
    amount_by_grantee_type: {
        limit: 0,
        groupBy: ['Grantee Type Description', 'Financial Assistance'],
    },
};

export const regionsQueries = {
    state_grant_by_year: {
        limit: 0,
        addCount: true,
        groupBy: ['State Name', 'Award Year'],
        sortAsc: false,
        sortField: 'COUNT',
        initialFilter: {},
    },
    state_grant_by_amount: {
        limit: 0,
        groupBy: ['State Name', 'Financial Assistance'],
        initialFilter: {},
    },
    state_grant: {
        addCount: true,
        groupBy: ['State Name'],
        sortAsc: false,
        sortField: 'COUNT',
        initialFilter: {},
    },
};

export const tableQueries = {
    reportCounts: {
        addCount: true,
        limit: 0,
        fields: ['_id'],
    },
    grant_list: {
        fields: [
            '_id',
            'Project Period Start Date Text String',
            'Grant Project Period End Date Text',
            'Financial Assistance',
            'Grantee Name',
            'Grantee Type Description',
            'State Name',
            'HRSA Program Area Name',
            'Grant Program Name',
        ],
        limit: 10,
        from: 0,
        sortAsc: false,
        sortField: 'Project Period Start Date Text String',
    },
};
export const dependentQueries = {
    reportCounts: {
        addCount: true,
        limit: 0,
        fields: ['_id'],
    },
};
