import { Reducer } from 'redux';
import { IDashboardAction, IDashboardState } from './DashboardModels';

//= =======Action Types=========

export const DASHBOARD_DATA_REQUEST = 'DASHBOARD_DATA_REQUEST';
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS';
export const DASHBOARD_SINGLE_DATA_REQUEST = 'DASHBOARD_SINGLE_DATA_REQUEST';
export const DASHBOARD_SINGLE_DATA_SUCCESS = 'DASHBOARD_SINGLE_DATA_SUCCESS';
export const DASHBOARD_SELECTED_PUBMED_DATA_SUCCESS = 'DASHBOARD_SELECTED_PUBMED_DATA_SUCCESS';
export const DASHBOARD_PUBMED_DATA_SUCCESS = 'DASHBOARD_PUBMED_DATA_SUCCESS';
export const DASHBOARD_DATA_FAILURE = 'DASHBOARD_DATA_FAILURE';

export const DASHBOARD_FILTER_REQUEST = 'DASHBOARD_FILTER_REQUEST';
export const DASHBOARD_FILTER_SUCCESS = 'DASHBOARD_FILTER_SUCCESS';
export const DASHBOARD_FILTER_FAILURE = 'DASHBOARD_FILTER_FAILURE';
export const DASHBOARD_SET_FILTER = 'DASHBOARD_SET_FILTER';
export const DASHBOARD_ADD_FILTER = 'DASHBOARD_ADD_FILTER';

export const DASHBOARD_RESET = 'DASHBOARD_RESET';

export const DASHBOARD_DOWNLOAD_REQUEST = 'DASHBOARD_DOWNLOAD_REQUEST';
export const DASHBOARD_DOWNLOAD_SUCCESS = 'DASHBOARD_DOWNLOAD_SUCCESS';
export const DASHBOARD_DOWNLOAD_FAILURE = 'DASHBOARD_DOWNLOAD_FAILURE';

export const DASHBOARD_EXPORT_REQUEST = 'DASHBOARD_EXPORT_REQUEST';
export const DASHBOARD_EXPORT_SUCCESS = 'DASHBOARD_EXPORT_SUCCESS';
export const DASHBOARD_EXPORT_FAILURE = 'DASHBOARD_EXPORT_FAILURE';

export const DASHBOARD_DATA_TIMESTAMP_REQUEST = 'DASHBOARD_DATA_TIMESTAMP_REQUEST';
export const DASHBOARD_DATA_TIMESTAMP_SUCCSS = 'DASHBOARD_DATA_TIMESTAMP_SUCCESS';
export const DASHBOARD_DATA_TIMESTAMP_FAILURE = 'DASHBOARD_DATA_TIMESTAMP_FAILURE';

export const COORDS_DATA_REQUEST = 'COORDS_DATA_REQUEST';
export const COORDS_DATA_SUCCESS = 'COORDS_DATA_SUCCESS';
export const COORDS_DATA_FAILURE = 'COORDS_DATA_FAILURE';

// reducer for shared actions
export const dashboard: Reducer<IDashboardState, IDashboardAction> = (state = { coordinates: [] }, action) => {
    switch (action.type) {
        case DASHBOARD_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DASHBOARD_SINGLE_DATA_REQUEST:
            return {
                ...state,
                [action.dataType + 'Loading']: true,
            };

        case DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                tableFilter: action.tableFilter,
                ...action.payload,
            };

        case DASHBOARD_SINGLE_DATA_SUCCESS:
            return {
                ...state,
                [action.dataType + 'Loading']: false,
                tableFilter: action.tableFilter,
                [action.dataType]: action.tableFilter?.searchBefore
                    ? {
                          ...action.payload,
                          items: action.payload?.items.reverse(),
                      }
                    : action.payload,
            };

        case DASHBOARD_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case DASHBOARD_FILTER_REQUEST:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.filterId]: {
                        loading: true,
                        error: null,
                    },
                },
            };

        // case DASHBOARD_FILTER_SUCCESS:
        //     return {
        //         ...state,
        //         filters: {
        //             ...state.filters,
        //             [action.filterId]: {
        //                 loading: false,
        //                 values: action.payload?.items[0].facet[`${action.filterId}Facet`].buckets.map(
        //                     (v: any) => v['_id'],
        //                 ),
        //             },
        //         },
        //     };

        case DASHBOARD_FILTER_SUCCESS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.filterId]: {
                        loading: false,
                        values: action.payload?.items.map((v: { [x: string]: any }) => v[action.filterId]),
                    },
                },
            };

        case DASHBOARD_FILTER_FAILURE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.filterId]: {
                        loading: false,
                        error: action.error,
                    },
                },
            };

        case DASHBOARD_SET_FILTER:
            return {
                ...state,
                filter: {
                    data: action.payload,
                    filter_timestamp: Date.now(),
                },
            };

        case DASHBOARD_ADD_FILTER:
            return {
                ...state,
                filter: {
                    data: {
                        ...((state.filter && state.filter.data) || {}),
                        ...action.payload,
                    },
                    filter_timestamp: Date.now(),
                },
            };

        case DASHBOARD_DOWNLOAD_REQUEST:
            return {
                ...state,
                files: {
                    [action.id]: {
                        downloading: true,
                        error: null,
                    },
                },
            };
        case DASHBOARD_DOWNLOAD_SUCCESS:
            return {
                ...state,
                files: {
                    [action.id]: {
                        downloading: false,
                        error: null,
                    },
                },
            };

        case DASHBOARD_DOWNLOAD_FAILURE:
            return {
                ...state,
                files: {
                    [action.id]: {
                        downloading: false,
                        error: action.error,
                    },
                },
            };

        case DASHBOARD_EXPORT_REQUEST:
            return {
                ...state,
                exporting: true,
                exportError: null,
            };

        case DASHBOARD_EXPORT_SUCCESS:
            return {
                ...state,
                exporting: false,
            };

        case DASHBOARD_EXPORT_FAILURE:
            return {
                ...state,
                exporting: false,
                exportError: action.error,
            };

        case DASHBOARD_DATA_TIMESTAMP_REQUEST:
            return {
                ...state,
                requestingTimestamp: true,
                timestampError: null,
            };
        case DASHBOARD_DATA_TIMESTAMP_SUCCSS:
            return {
                ...state,
                requestingTimestamp: false,
                timestamp: action.timestamp,
                hederaTopic: action.topic,
                hederaTransaction: action.transaction,
            };
        case DASHBOARD_DATA_TIMESTAMP_FAILURE:
            return {
                ...state,
                timestampError: action.error,
            };

        case COORDS_DATA_REQUEST:
            return {
                ...state,
                loadingCoordinates: true,
            };
        case COORDS_DATA_SUCCESS:
            return {
                ...state,
                loadingCoordinates: false,
                coordinates: [...state.coordinates, ...action.payload?.world_coords],
            };
        case COORDS_DATA_FAILURE:
            return {
                ...state,
                loadingCoordinates: false,
            };

        case DASHBOARD_RESET:
            return { coordinates: [] };

        default:
            return state;
    }
};
