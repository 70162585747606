import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';

// styling
import './index.css';
import { getDesignTokens } from './styles/theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

// views
import DashboardView from './views/DashboardView';
import HomeView from './views/HomeView';
import CompanionView from './views/CompanionView';
import NotFoundView from './views/NotFoundView';
import AboutView from './views/AboutView';
import LogoutView from './views/LogoutView';

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {
        // This is intentional
    },
});

export const App = () => {
    // const [cookies, setCookies] = useCookies(['hde_mode']);
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const initialModeState: string | (() => string) = 'light';
    const [mode] = React.useState<PaletteMode | string>(initialModeState);
    const theme = React.useMemo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        () => createTheme(getDesignTokens(mode)),
        [mode],
    );

    useEffect(() => {
        const setAuthToken = async () => {
            const tokenCookie = Cookies.get('auth0_token'); // Get token from 3001
            const userCookie = Cookies.get('auth0_user'); // Get token from 3001
            if (isAuthenticated && user && !tokenCookie && !userCookie) {
                try {
                    const token = await getAccessTokenSilently();
                    Cookies.set('auth0_token', token, {
                        secure: true, // Use HTTPS (for production)
                        path: '/', // Accessible across all paths
                        domain: '.organdata.org', // Accessible across subdomains
                    });
                    Cookies.set('auth0_user', user.name, {
                        secure: true, // Use HTTPS (for production)
                        path: '/', // Accessible across all paths
                        domain: '.organdata.org', // Accessible across subdomains
                    });
                    console.log('Auth token set in cookies');
                } catch (error) {
                    console.error('Failed to retrieve token:', error);
                }
            }
        };

        setAuthToken();
    }, [isAuthenticated, user]); // Add dependencies

    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route exact path="/" element={<HomeView />} />
                <Route exact path="/dashboard/:id" element={<DashboardView />} />
                <Route exact path="/about" element={<AboutView />} />
                <Route exact path="/ai-companion" element={<CompanionView />} />
                <Route exact path="/logout" element={<LogoutView />} />
                <Route path="*" element={<NotFoundView />} />
            </Routes>
        </ThemeProvider>
    );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
export default App;
