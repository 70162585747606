import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import { Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { IDashboardCompProps } from './DashboardModels';

// ui reflection of file view
const DashboardExportSnackbar: React.FC<IDashboardCompProps> = () => {
    const [exportingPdf, setExportingPdf] = useState(false);
    const exportingTable = useSelector(
        (state: any) =>
            state.dashboard &&
            state.dashboard.files &&
            state.dashboard.files.tableData &&
            state.dashboard.files.tableData.downloading,
    );

    useEffect(() => {
        if (exportingTable) {
            setExportingPdf(true);
        }
        return () => {
            handleSnackbarClose();
        };
    }, [exportingTable]);

    const handleSnackbarClose = () => {
        setExportingPdf(false);
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={exportingPdf}
                key={'exporting_circular'}
                TransitionComponent={Fade}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    // className: classes.snackbarContent,
                    elevation: 0,
                }}
                message={
                    <Tooltip
                        title={
                            'This download may take a few minutes due to the large amount of data in the CSV file. Please be patient as we ensure all the information is correctly compiled and delivered.'
                        }
                    >
                        <Grid container alignItems={'center'}>
                            <Grid item xs={12}>
                                <LinearProgress />
                            </Grid>
                            <Grid item>
                                <Typography variant={'caption'} color={'primary'}>
                                    Downloading Trials List
                                </Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                }
            />
        </div>
    );
};

export default DashboardExportSnackbar;
