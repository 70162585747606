export const queries = {
    national_overview: {
        fields: [
            'organ',
            'year',
            'deceased_organ_donors_recovered',
            'living_organ_donors_recovered',
            'candidates_added',
            'registrations_added',
            'deceased_donor_organ_transplant_recipients',
            'living_donor_organ_transplant_recipients',
        ],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Overview - National',
            },
            organ: {
                type: 'STRING',
                eq: 'All',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    national_overview_by_organ: {
        groupBy: [
            'organ',
            'deceased_organ_donors_recovered',
            'living_organ_donors_recovered',
            'candidates_added',
            'registrations_added',
            'deceased_donor_organ_transplant_recipients',
            'living_donor_organ_transplant_recipients',
        ],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Overview - National',
            },
            organ: {
                type: 'STRING',
                ne: 'All',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    state_deceased_organ_donor_overview: {
        limit: 300,
        groupBy: ['organ', 'state', 'counts'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Overview - State',
            },
            statistics: {
                type: 'STRING',
                eq: 'Deceased Organ Donors',
            },
            state: {
                type: 'STRING',
                ne: 'All',
            },
            organ: {
                type: 'STRING',
                ne: 'All',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    state_living_organ_donor_overview: {
        limit: 300,
        groupBy: ['organ', 'state', 'counts'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Overview - State',
            },
            statistics: {
                type: 'STRING',
                eq: 'Living Organ Donors',
            },
            state: {
                type: 'STRING',
                ne: 'All',
            },
            organ: {
                type: 'STRING',
                ne: 'All',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    state_waitlist_candidates_overview: {
        limit: 300,
        groupBy: ['organ', 'state', 'counts'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Overview - State',
            },
            statistics: {
                type: 'STRING',
                eq: 'Waitlist Candidates',
            },
            state: {
                type: 'STRING',
                ne: 'All',
            },
            organ: {
                type: 'STRING',
                ne: 'All',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    state_transplant_recipients_overview: {
        limit: 300,
        groupBy: ['organ', 'state', 'counts'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Overview - State',
            },
            statistics: {
                type: 'STRING',
                eq: 'Transplant Recipients',
            },
            state: {
                type: 'STRING',
                ne: 'All',
            },
            organ: {
                type: 'STRING',
                ne: 'All',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
};

export const demographicQueries = {
    recipient_demographics_sex: {
        fields: ['organ', 'male', 'female', 'donor_type'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'OTC Transplants by Demographics',
            },
            OTC_Name_State: {
                type: 'STRING',
                in: ['All'],
            },
            organ: {
                type: 'STRING',
                nin: ['All Transplanted Organ Types'],
            },
        },
    },
    recipient_demographics_age: {
        fields: ['organ', '0-17', '18-30', '31-40', '41-50', '51-60', '60-100', 'age_not_reported', 'donor_type'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'OTC Transplants by Demographics',
            },
            OTC_Name_State: {
                type: 'STRING',
                in: ['All'],
            },
            organ: {
                type: 'STRING',
                nin: ['All Transplanted Organ Types'],
            },
        },
    },
    recipient_demographics_race: {
        fields: [
            'organ',
            'white',
            'black',
            'hispanic',
            'asian',
            'american_indian',
            'native_hawaiian',
            'multiracial',
            'donor_type',
        ],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'OTC Transplants by Demographics',
            },
            OTC_Name_State: {
                type: 'STRING',
                in: ['All'],
            },
            organ: {
                type: 'STRING',
                nin: ['All Transplanted Organ Types'],
            },
        },
    },
    donor_demographics_overview: {
        fields: ['category', 'donor_type', 'level', 'donor_count'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Donor Demographics',
            },
            category: {
                type: 'STRING',
                in: ['1 - Donor Type'],
            },
        },
    },
    donor_demographics_sex: {
        fields: ['category', 'donor_type', 'level', 'donor_count'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Donor Demographics',
            },
            category: {
                type: 'STRING',
                in: ['4 - Sex'],
            },
            donor_type: {
                type: 'STRING',
                in: ['All'],
            },
        },
    },
    donor_demographics_race: {
        fields: ['category', 'donor_type', 'level', 'donor_count'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Donor Demographics',
            },
            category: {
                type: 'STRING',
                in: ['5 - Race/Ethnicity'],
            },
            donor_type: {
                type: 'STRING',
                in: ['All'],
            },
        },
    },
    donor_demographics_age: {
        fields: ['category', 'donor_type', 'level', 'donor_count'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Donor Demographics',
            },
            category: {
                type: 'STRING',
                in: ['6 - Age Group'],
            },
            donor_type: {
                type: 'STRING',
                in: ['All'],
            },
        },
    },
    donor_demographics_year: {
        fields: ['category', 'donor_type', 'level', 'donor_count'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Donor Demographics',
            },
            category: {
                type: 'STRING',
                in: ['3 - Year'],
            },
            donor_type: {
                type: 'STRING',
                in: ['All'],
            },
        },
    },
};

export const opoOtcQueries = {
    opo_collection: {
        limit: 1500,
        fields: ['opo_name', 'state'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'OPO - POTR',
            },
            opo_name: {
                type: 'STRING',
                ne: 'Average of All OPOs',
            },
            organ: {
                type: 'STRING',
                eq: 'Kidney',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    opo_collection_by_state: {
        addCount: true,
        groupBy: ['state'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'OPO - POTR',
            },
            opo_name: {
                type: 'STRING',
                ne: 'Average of All OPOs',
            },
            organ: {
                type: 'STRING',
                eq: 'Kidney',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    opo_transplant_rate: {
        limit: 1500,
        groupBy: ['opo_name', 'organ', 'procured_organ_transplant_rate'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'OPO - POTR',
            },
            opo_name: {
                type: 'STRING',
                ne: 'Average of All OPOs',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    otc_collection: {
        limit: 1500,
        fields: ['transplant_center', 'state'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist - OTC Transplant Rate',
            },
            organ: {
                type: 'STRING',
                eq: 'All',
            },
        },
    },
    otc_collection_by_state: {
        addCount: true,
        groupBy: ['state'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist - OTC Transplant Rate',
            },
            organ: {
                type: 'STRING',
                eq: 'All',
            },
        },
    },
    otc_transplant_rate: {
        limit: 1500,
        groupBy: [
            'transplant_center',
            'deceased_donor_transplant_month',
            'deceased_donor_transplant_year',
            'deceased_donor_transplant_two_years',
            'deceased_donor_transplant_three_years',
        ],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist - OTC Transplant Rate',
            },
            organ: {
                type: 'STRING',
                eq: 'All',
            },
        },
    },
};

export const waitlistQueries = {
    candidates_registrations: {
        fields: ['type', 'organ', 'total'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist-Candidate Registration',
            },
            organ: {
                type: 'STRING',
                eq: 'All',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    candidates_registrations_organ: {
        fields: ['type', 'organ', 'total'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist-Candidate Registration',
            },
            organ: {
                type: 'STRING',
                ne: 'All',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    waitlist_sex: {
        fields: ['organ', 'male', 'female'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist-Candidate Registration',
            },
            type: {
                type: 'STRING',
                eq: 'Candidates',
            },
            organ: {
                type: 'STRING',
                eq: 'All',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    waitlist_sex_organ: {
        fields: ['organ', 'male', 'female'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist-Candidate Registration',
            },
            organ: {
                type: 'STRING',
                ne: 'All',
            },
            type: {
                type: 'STRING',
                eq: 'Candidates',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    waitlist_demographics: {
        fields: ['organ', 'white', 'black', 'hispanic', 'asian', 'american_indian', 'native_hawaiian', 'multiracial'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist-Candidate Registration',
            },
            organ: {
                type: 'STRING',
                eq: 'All',
            },
            type: {
                type: 'STRING',
                eq: 'Candidates',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    waitlist_demographics_organ: {
        fields: ['organ', 'white', 'black', 'hispanic', 'asian', 'american_indian', 'native_hawaiian', 'multiracial'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist-Candidate Registration',
            },
            organ: {
                type: 'STRING',
                ne: 'All',
            },
            type: {
                type: 'STRING',
                eq: 'Candidates',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    waitlist_age: {
        fields: ['organ', '0-17', '18-30', '31-40', '41-50', '51-60', '61-100'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist-Candidate Registration',
            },
            organ: {
                type: 'STRING',
                eq: 'All',
            },
            type: {
                type: 'STRING',
                eq: 'Candidates',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
    waitlist_age_organ: {
        fields: ['organ', '0-17', '18-30', '31-40', '41-50', '51-60', '61-100'],
        initialFilter: {
            source: {
                type: 'STRING',
                eq: 'Waitlist-Candidate Registration',
            },
            organ: {
                type: 'STRING',
                ne: 'All',
            },
            type: {
                type: 'STRING',
                eq: 'Candidates',
            },
            year: {
                type: 'NUMERICAL',
                eq: 2024,
            },
        },
    },
};
export const dependentQueries = {};
