import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import config from './auth.config';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { defaultStore } from './components/Shared/Redux/defaultStore';

// Redux
import history from './components/Shared/history';

// Components
import App from './App';

const Auth = () => {
    const onRedirectCallback = (appState: any) => {
        history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
    };
    return (
        <div>
            <Auth0Provider
                domain={config.domain as string}
                clientId={config.clientId as string}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    scope: config.scope,
                    audience: config.audience,
                }}
                onRedirectCallback={onRedirectCallback}
            >
                <Provider store={defaultStore}>
                    <Router history={history}>
                        <App />
                    </Router>
                </Provider>
            </Auth0Provider>
        </div>
    );
};

export default Auth;
