import overviewFilters from './dashboards/organ-procurement/filters';
import kidneyFilters from './dashboards/kidney/filters';
import liverFilters from './dashboards/liver/filters';
import grantsFilters from './dashboards/grants/filters';

const filters = (type: string) => {
    switch (type) {
        case 'overview':
            return overviewFilters;
        case 'kidney':
            return kidneyFilters;
        case 'liver':
            return liverFilters;
        case 'grants':
            return grantsFilters;
        default:
            return null;
    }
};

export default filters;
