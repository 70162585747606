export const sortByTotal = (data: any, keys: string[]) =>
    data.sort((a: string, b: string) => {
        const totalA = keys.reduce((accum, obj) => (a[obj] ? accum + a[obj] : accum), 0);
        const totalB = keys.reduce((accum, obj) => (b[obj] ? accum + b[obj] : accum), 0);
        return totalA - totalB;
    });

export const getDate = (type: string) => {
    let dt;
    let filterValue;
    if (type === 'Last 24 Hours') {
        dt = new Date(Date.now() - 24 * 60 * 60 * 1000);
    } else if (type === 'Last 7 Days') {
        dt = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    } else if (type === 'Last 30 Days') {
        dt = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    }

    if (dt) {
        filterValue = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    }

    return filterValue;
};
